const Utilities = {
  darkenHexColor: (hex: string, percent: number) => {
    // Ensure percent is between 0 and 100
    const amount = (100 - Math.max(0, Math.min(100, percent))) / 100;

    // Convert hex to RGB
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    // Calculate the new color value
    r = Math.round(r * amount);
    g = Math.round(g * amount);
    b = Math.round(b * amount);

    // Convert the new RGB back to hex
    const rHex = r.toString(16).padStart(2, '0');
    const gHex = g.toString(16).padStart(2, '0');
    const bHex = b.toString(16).padStart(2, '0');

    // Return the formatted hex color
    return `#${rHex}${gHex}${bHex}`;
  }
}

export { Utilities }