import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { AudioClipInfo } from '../models/AudioClipInfo'
import { Photo } from 'pexels'

export default function CreditsTab() {

  const currentPhoto: Photo | undefined = useSelector((state: RootState) => state.app.currentPhoto);
  
  const audioClipInfo: AudioClipInfo | null = useSelector((state: RootState) => state.audioPlayer.backgroundMusic);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <div id='credits-tab' className='credits-tab'> 
      {
        currentPhoto && (<p>Photo: <a href={currentPhoto.photographer_url}>{currentPhoto.photographer}</a> - {"Peskels"}</p>)
      }   
      {
        audioClipInfo != null ? (
          <p>Audio: {audioClipInfo.artist} - {audioClipInfo.source}</p>
        ) : (
          <></>
        )
      }            
    </div>
  )
}
