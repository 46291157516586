// Import createSlice from Redux Toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Photo } from 'pexels';
import { Utilities } from '../Utilities';
import { act } from 'react-dom/test-utils';

// Define a type for the slice state
export interface AppState {
  showSettingsModal: boolean;
  /** bool value if true, countdown and its UI should be disabled */
  countdownDisabled: boolean;
  currentPhoto: Photo | undefined;  
  siteColour: string;
  disabledSiteColour: string;
  slideshowImages: boolean;
  backgroundImageQuery: string;
}

const initialState: AppState = {
  showSettingsModal: false,
  countdownDisabled: false,
  currentPhoto: undefined,  
  siteColour: localStorage.getItem("site-colour") ?? "#024951",
  disabledSiteColour: localStorage.getItem("disabled-site-colour") ?? Utilities.darkenHexColor("#024951", 20),
  slideshowImages: true,
  backgroundImageQuery: "lake water river ocean"
};

function saveSateToLocalStorage(state: AppState) {
  state.showSettingsModal = false;
  let jsonString = JSON.stringify(state);
  localStorage.setItem("state", jsonString);
}

function loadStateFromLocalStorage(): AppState | undefined {
  let stateString = localStorage.getItem("state");
  if (stateString === null) {
    return undefined;
  }
  let state: AppState | undefined = JSON.parse(stateString);
  return state;
}

export const appSlice = createSlice({
  name: 'app',
  initialState: loadStateFromLocalStorage() ?? initialState,
  reducers: {
    SHOW_SETTINGS_MODAL: (state) => {
      state.showSettingsModal = true;
    },
    HIDE_SETTINGS_MODAL: (state) => {
      state.showSettingsModal = false;
    },
    SET_COUNTDOWN_DISABLED: (state, action: PayloadAction<boolean>) => {
      state.countdownDisabled = action.payload;      
    },
    SET_CURRENT_PHOTO: (state, action: PayloadAction<Photo>) => {
      state.currentPhoto = action.payload;
    },
    SET_SITE_COLOUR: (state, action: PayloadAction<string>) => {
      state.siteColour = action.payload;
      state.disabledSiteColour = Utilities.darkenHexColor(state.siteColour, 20);
      saveSateToLocalStorage(state);      
    },
    SET_SLIDESHOW_IMAGES: (state, action: PayloadAction<boolean>) => {
      state.slideshowImages = action.payload;
      saveSateToLocalStorage(state);
    },
    SET_BACKGROUND_IMAGE_QUERY: (state, action: PayloadAction<string>) => {
      state.backgroundImageQuery = action.payload;
      saveSateToLocalStorage(state);
    }
  }
});

// Export actions to be used in components
export const { SHOW_SETTINGS_MODAL, 
  HIDE_SETTINGS_MODAL, 
  SET_COUNTDOWN_DISABLED, 
  SET_CURRENT_PHOTO, 
  SET_SITE_COLOUR,
  SET_SLIDESHOW_IMAGES,
  SET_BACKGROUND_IMAGE_QUERY
} = appSlice.actions;

// Export the reducer to be used in the store
export default appSlice.reducer;