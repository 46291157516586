import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react'
import RoundIconButton from './RoundButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface DropdownProps {
  children: ReactNode;
  title?: string | undefined;
  hiddenTooltip: string;
  shownTooltip: string;
  onClose: () => void;
  onOpen: () => void;
  enabled: boolean;
}

export default function (props: DropdownProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [renderContent, setRenderContent] = useState<boolean>(false);

  useEffect(() => {
    if (isVisible) {
      setRenderContent(true);
      props.onOpen();
    }
    else {
      props.onClose();
    }
  }, [isVisible]);

  function onTransitionEnd() {
    if (!isVisible) setRenderContent(false);
  };

  function toggleDropdown() {
    setIsVisible(!isVisible);
  };

  const siteColour = useSelector((state: RootState) => state.app.siteColour);
  const disabledColour = useSelector((state: RootState) => state.app.disabledSiteColour);

  return (
    <div className="dropdown">
      <div className="dropdown-bar">
        {props.title && <h1>{props.title}</h1>}
        <Tooltip title={isVisible ? props.shownTooltip : props.hiddenTooltip}>
          <div style={{paddingBottom: "0.25em"}}>
            <RoundIconButton size={40} icon={isVisible ? faAngleUp : faAngleDown} type={'filled'} 
              colour={siteColour} disabledColour={disabledColour} onClick={toggleDropdown} disabled={!props.enabled} />        
          </div>
        </Tooltip>
      </div>
      {renderContent && (
        <div
          className={`dropdown-content ${isVisible ? 'visible' : ''}`}
          onTransitionEnd={onTransitionEnd}
          style={{ display: renderContent ? 'block' : 'none' }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
}
