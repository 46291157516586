import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  size: number;
  icon: FontAwesomeIconProps["icon"];
  type: "filled" | "outlined";
  colour: string;
  disabledColour: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export default function RoundIconButton(props: Props) {
  const divRef = useRef(null);
  const [fontSize, setFontSize] = useState('16px');
  
  const roundButtonStyles: React.CSSProperties = {
    minWidth: `${props.size}px`,
    maxWidth: `${props.size}px`,
    minHeight: `${props.size}px`,
    maxHeight: `${props.size}px`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: fontSize
  };

  const filledButtonStyles: React.CSSProperties = {
    border: "none",
    backgroundColor: `${props.disabled ? props.disabledColour : props.colour}`,
    color: "white",  
    padding: "2% 4%",    
    margin: "0% 2%",
  };

  const outlinedButtonStyles: React.CSSProperties = {
    border: `3px solid ${props.disabled ? props.disabledColour : props.colour}`,
    backgroundColor: "transparent",
    color: `${props.disabled ? props.disabledColour : props.colour}`,
    padding: "1.5% 3%",
    margin: "0% 2%"
  }

  const buttonStyles: React.CSSProperties = props.type === "filled" ? {
    ...roundButtonStyles,
    ...filledButtonStyles
  } : {
    ...roundButtonStyles,
    ...outlinedButtonStyles
  };


  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {        
        const newFontSize = Math.max(10, entry.contentRect.width / 1.25) + 'px';
        setFontSize(newFontSize);
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <button ref={divRef} style={buttonStyles} onClick={props.onClick} disabled={props.disabled}>
      <FontAwesomeIcon icon={props.icon} style={{fontSize: "28px"}}/>
    </button>
  )
}
