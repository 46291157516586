import '.././styles.css';
import BackgroundImage from '../components/BackgroundImage';
import Content from '../Content';
import SettingsButton from '../components/SettingsButton';
import CreditsTab from '../components/CreditsTab';
import SettingsModal from '../modals/SettingsModal';
import TitleComponent from '../components/TitleComponent';

function App() {

  return (
    <div>
      <div className='App'>  
        <TitleComponent/>        
        <SettingsButton/>
        <BackgroundImage/>         
        <Content/>
        <CreditsTab/>                      
      </div>
      <SettingsModal/>
    </div>
  );
}

export default App;
