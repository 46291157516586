import React, { useState } from 'react'

export default function TitleComponent() {
  const tagLines: string[] = [
    "Find Your Zen, Meditate, Relax, and Reconnect with Yourself.",
    "Serenity Awaits, Dive Deep into Meditation with a Single Click.",
    "Breathe, Relax, and Explore Inner Peace with Every Timer Countdown.",
    "Unwind Your Mind, Your Gateway to Serene Moments and Mindful Living.",
    "Transform Your Space into a Sanctuary of Calm with Every Session.",    
    "Harmony at Your Fingertips: Discover the Power of Mindful Meditation.",    
  ];

  const headerStyling: React.CSSProperties = {
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%)',
    position: 'absolute',
    width: "90vw",
    zIndex: 999
  };
  const headerTextStyling: React.CSSProperties = {
    color: 'white',
    fontSize: '4em',
    margin: 0,
  };
  const subStyling: React.CSSProperties = {
    color: 'white',
    lineHeight: '50%',    
    fontSize: '1em'
  };

  const [tagLine, setTagLine] = useState<string>(getRandomTagLine());

  function getRandomTagLine() {
    let randInt: number = Math.floor(Math.random() * tagLines.length - 1);
    return tagLines[randInt];
  }

  return (
    <header style={headerStyling}>
      <h1 style={headerTextStyling}>ZenTime</h1>
      <sub style={subStyling}>{tagLine}</sub>
    </header>
  )
}
