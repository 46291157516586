import './styles.css';
import CountdownTimer, { getTimerDisplayString } from './components/CountdownTimer';
import { useDispatch, useSelector } from 'react-redux';
import AudioPlayer from './components/AudioPlayer';
import { RootState } from './store';
import Dropdown from './components/generic-components/Dropdown';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { SET_START_TIME } from './features/countdownTimerSlice';
import { SET_COUNTDOWN_DISABLED } from './features/appSlice';
import RoundIconButton from './components/generic-components/RoundButton';

import 'react-circular-progressbar/dist/styles.css';

export default function Content() {
  /** time increment size in seconds */
  const INCREMENT_SIZE = 15;
  /** minimum amount of time in seconds the timer can be set to */
  const MIN_TIMER = 15;

  const dispatcher = useDispatch();
  const countdownTimerState = useSelector((state: RootState) => state.countdownTimer);
  const active              = useSelector((state: RootState) => state.countdownTimer.active);
  const reachedZero         = useSelector((state: RootState) => state.countdownTimer.reachedZero);
  const siteColour          = useSelector((state: RootState) => state.app.siteColour);
  const disabledSiteColour  = useSelector((state: RootState) => state.app.disabledSiteColour);
  const showSettingsModal   = useSelector((state: RootState) => state.app.showSettingsModal);

  const [timeInSeconds, setTimeInSeconds] = useState<number>(0);  


  function increaseTime() {
    setTimeInSeconds(timeInSeconds + INCREMENT_SIZE);
  }

  function decrementTimer() {
    let newTime = Math.max(timeInSeconds - INCREMENT_SIZE, MIN_TIMER);
    setTimeInSeconds(newTime);
  }

  function handleOpenConfigMenu() {
    if (!countdownTimerState.active && countdownTimerState.startTime > 0) {
      setTimeInSeconds(countdownTimerState.startTime);
      // disable countdown's timer and UI - to prevent users from starting countdown when configuring it
      dispatcher(SET_COUNTDOWN_DISABLED(true));
    }
  }

  function handleCloseConfigMenu() {
    dispatcher(SET_COUNTDOWN_DISABLED(false));
    // enable countdown's timer and UI
    dispatcher(SET_START_TIME(timeInSeconds));    
  }

  function ConfigTimerMenu() {
    return (
      <div style={{padding: "0.5em"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", color: siteColour}}>
          <RoundIconButton size={45} icon={faPlus} type={'filled'} colour={siteColour} disabledColour={disabledSiteColour} onClick={increaseTime}/>  
          <p style={{fontWeight: "bold", fontSize: "xx-large", lineHeight: "0%"}}>{getTimerDisplayString(timeInSeconds)}</p>
          <RoundIconButton size={45} icon={faMinus} type={'filled'} colour={siteColour} disabledColour={disabledSiteColour} onClick={decrementTimer}/>            
        </div>
      </div>
    )
  }  

  return (
    <div className='centered-div' style={{display: showSettingsModal ? "none" : "block"}}>  
      <Dropdown hiddenTooltip={'Config timer'} shownTooltip={'Update changes'} 
        onClose={handleCloseConfigMenu} onOpen={handleOpenConfigMenu}
        enabled={!active || reachedZero}>
          <ConfigTimerMenu/>
      </Dropdown>         
      <CountdownTimer/>                 
      <AudioPlayer/>        
    </div>
  )
}
