import { faPause, faStop, faRotateRight, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, MouseEvent, useRef } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import '../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ACTIVE } from '../features/audioPlayerSlice';
import { SET_REACHED_ZERO, SET_SECONDS_LEFT, SET_START_TIME, TOGGLE_ACTIVE, getTimeFromSearchParams } from '../features/countdownTimerSlice';
import { RootState } from '../store';
import RoundIconButton from './generic-components/RoundButton';
import { Utilities } from '../Utilities';
import { CircularProgressbarStyles } from 'react-circular-progressbar/dist/types';

export default function CountdownTimer() {  
  const dispatcher = useDispatch();
  
  const countDownTimerState = useSelector((state: RootState) => state.countdownTimer);
  const appState = useSelector((state: RootState) => state.app);

  dispatcher(SET_SECONDS_LEFT(countDownTimerState.secondsLeft));

  const [percentage, setPercentage] = useState<number>(100);  

  // countdown state
  const [ticks, setTicks] = useState<number>(0);    

  function handlerTimerReachingZero() {    
    dispatcher(SET_REACHED_ZERO(true));
    stopTimer();
  }

  function deductFromTimer() {
    if (countDownTimerState.active && countDownTimerState.secondsLeft > 0) { 
      dispatcher(SET_SECONDS_LEFT(countDownTimerState.secondsLeft - 1));        
    }
    else if (countDownTimerState.secondsLeft <= 0 && countDownTimerState.active) {
      // countdown has reached 0
      dispatcher(SET_ACTIVE(false)); // Stop the countdown
      handlerTimerReachingZero();            
    }
    else if (!countDownTimerState.active){
      // timer activity has been set to false
      dispatcher(SET_ACTIVE(false)); // Stop the countdown      
    }
  }

  useEffect(() => {
    deductFromTimer();   
  }, [ticks]);

  useEffect(() => {
    const worker = new Worker(new URL('../TimerWorker.ts', import.meta.url), { type: 'module' });
    // start worker
    worker.postMessage(1000);

    worker.onmessage = (event: MessageEvent) => {
      if (event.data === 'tick') {
        setTicks(t => t + 1);        
      }
    }

    // Clean up the worker when the component unmounts
    return () => {
      worker.terminate();
    };    
  }, [countDownTimerState.active, countDownTimerState.secondsLeft]);


  useEffect(() => {
    calculatePercentage();    
    if (!countDownTimerState.reachedZero) {
      document.title = `ZenTime (${getTimerDisplayString(countDownTimerState.secondsLeft)})`;
    }
  }, [countDownTimerState.secondsLeft]);

  useEffect(() => {
    if (countDownTimerState.reachedZero) {
      document.title = `ZenTime - Time's Up!`;
    }
  }, [countDownTimerState.reachedZero]);

  useEffect(() => {
    let startTime = getTimeFromSearchParams();
    dispatcher(SET_START_TIME(startTime)); 
  }, []);

  function removeDuplicateProgressBarElements() {
    const elements = document.querySelectorAll('.MyClassName');

    // Iterate over all found elements, starting from the second element
    for (let i = 1; i < elements.length; i++) {
      // Remove the element from the DOM
      elements[i].parentNode?.removeChild(elements[i]);
    }
  }

  function startTimer() {    
    if (countDownTimerState.secondsLeft > 0) {                
      dispatcher(TOGGLE_ACTIVE());
      dispatcher(SET_REACHED_ZERO(false));
    }
  };

  function stopTimer() {
    // alert("stopped timer");
    if (countDownTimerState.active) {
      dispatcher(TOGGLE_ACTIVE());   
    }
  }

  function resetTimer() {
    // alert("reset timer");
    dispatcher(SET_REACHED_ZERO(false));
    dispatcher(SET_SECONDS_LEFT(countDownTimerState.startTime));    
    dispatcher(SET_ACTIVE(true));  
  }

  function toggleTimer() {
    if (countDownTimerState.active) {
      stopTimer();
    }
    else {
      startTimer();
    }
  }

  function calculatePercentage() {    
    let percentage: number = (countDownTimerState.secondsLeft / countDownTimerState.startTime) * 100;
    percentage = Math.floor(percentage);    
    setPercentage(percentage);
  }

  
  const siteColour = useSelector((state: RootState) => state.app.siteColour);
  const disabledSiteColour = useSelector((state: RootState) => state.app.disabledSiteColour);  
  
  const enabledCircularProgessBarStyling = useRef<CircularProgressbarStyles>(
    buildStyles({
      pathColor: siteColour,
      trailColor: '#d6d6d6',
      textColor: siteColour,
      textSize: "24px",    
      pathTransitionDuration: 0.5, 
    })
  );
  const disabledCircularProgessBarStyling = useRef<CircularProgressbarStyles>(
    buildStyles({
      pathColor: disabledSiteColour,
      trailColor: '#d6d6d6',
      textColor: disabledSiteColour,
      textSize: "24px",    
      pathTransitionDuration: 0.5, 
    })
  );
  

  useEffect(() => {    
    disabledCircularProgessBarStyling.current = buildStyles({
      pathColor: disabledSiteColour,
      trailColor: '#d6d6d6',
      textColor: disabledSiteColour,
      textSize: "24px",    
      pathTransitionDuration: 0.5, 
    });

    enabledCircularProgessBarStyling.current = buildStyles({
      pathColor: siteColour,
      trailColor: '#d6d6d6',
      textColor: siteColour,
      textSize: "24px",    
      pathTransitionDuration: 0.5, 
    });

  }, [siteColour]);

  return (
    <div style={{display: "relative"}}>            
      <CircularProgressbar value={percentage} text={getTimerDisplayString(countDownTimerState.secondsLeft)} 
        styles={appState.countdownDisabled ? disabledCircularProgessBarStyling.current : enabledCircularProgessBarStyling.current}/>       

      <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "2em"}}>
        <RoundIconButton 
          onClick={toggleTimer} 
          icon={countDownTimerState.active ? faPause : faPlay} 
          // disabled={appState.countdownDisabled} 
          disabled={false}
          disabledColour={disabledSiteColour}
          size={75} 
          colour={siteColour} 
          type={'outlined'} 
        />      
        <RoundIconButton 
          size={75} 
          colour={siteColour} 
          icon={faStop} 
          onClick={stopTimer} 
          disabled={appState.countdownDisabled} 
          type={'filled'} 
          disabledColour={disabledSiteColour}
        />  
        <RoundIconButton 
          size={75} 
          colour={siteColour} 
          icon={faRotateRight} 
          onClick={resetTimer} 
          disabled={appState.countdownDisabled} 
          type={'outlined'} 
          disabledColour={disabledSiteColour}
        />        
      </div>
      
    </div>
  );
};

export function getTimerDisplayString(secondsLeft: number): string {
  let minutes = Math.floor(secondsLeft / 60);
  let seconds = secondsLeft % 60;    
  let displayString: string = `${minutes.toString().padStart(2, '0').padEnd(2, '0')}:${seconds.toString().padStart(2, '0').padEnd(2, '0')}`;
  return displayString;
}
