import { ErrorResponse, Photo, PhotosWithTotalResults, createClient } from "pexels"


const PeskelsApi = {
  fetchImages: async(query: string, orientation: "landscape" | "orientation" | "square", numOfImages: number, page: number) => {
    const API_KEY = process.env.REACT_APP_PESKELS_API_KEY as string;
    const client = createClient(API_KEY)
    const response: PhotosWithTotalResults | ErrorResponse = await client.photos.search({ query, per_page: numOfImages, page: page});
    if (!isError(response)) {
      return response.photos;
    }          
  }
}

export { PeskelsApi }

function isError(response: any): response is ErrorResponse {
  return response && response.status && response.error;
}