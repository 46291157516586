import { configureStore } from '@reduxjs/toolkit';
// Import the slice reducer
import countdownTimerReducer from './features/countdownTimerSlice';
import audioPlayerReducer from './features/audioPlayerSlice';
import appReducer from './features/appSlice';

export const store = configureStore({
  reducer: {    
    countdownTimer: countdownTimerReducer,
    audioPlayer: audioPlayerReducer,
    app: appReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
