// Import createSlice from Redux Toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AudioClipInfo as AudioClipInfo } from '../models/AudioClipInfo';
import audioClipsData from '../data/AudioClipsData.json';
import alarmTonesData from '../data/AlarmTonesData.json';

// Define a type for the slice state
export interface AudioPlayerState {
  backgroundMusic: AudioClipInfo | null;
  audioClipsData: AudioClipInfo[];
  active: boolean;
  loop: boolean;
  alarmTone: AudioClipInfo | null;
  alarmTonesData: AudioClipInfo[];  
  playAlarmTone: boolean;
}

const initialState: AudioPlayerState = {
  backgroundMusic: null,
  audioClipsData: audioClipsData,
  active: false,
  loop: true,
  alarmTone: null,
  alarmTonesData: alarmTonesData,  
  playAlarmTone: true
};

function getAudioClipById(audioClips: AudioClipInfo[], id: string): AudioClipInfo | undefined {
  const audioClip: AudioClipInfo | undefined = audioClips.find(clip => clip.id === id);
  return audioClip;
}

export const audioPlayerSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    SET_AUDIO_CLIP: (state, action: PayloadAction<AudioClipInfo>) => {
      state.backgroundMusic = action.payload;
    },
    SET_AUDIO_CLIP_BY_INDEX: (state, action: PayloadAction<number>) => {
      if (action.payload < 0) {
        throw Error("Couldn't set audio clip by index because given index is less than 0");
      }
      else if (action.payload > state.audioClipsData.length - 1) {
        throw Error("Couldn't set audio clip by index because index is out of bounds");
      }
      else {
        state.backgroundMusic = state.audioClipsData[action.payload];
      }
    },
    SET_AUDIO_CLIP_BY_ID: (state, action: PayloadAction<string>) => {      
      let audioClip: AudioClipInfo | undefined = getAudioClipById(state.audioClipsData, action.payload);
      if (audioClip === undefined) {
        throw Error("Couldn't set alarm tone, invalid id given");
      }
      state.backgroundMusic = audioClip;
    },
    SET_ALARM_TONE: (state, action: PayloadAction<AudioClipInfo>) => {
      state.alarmTone = action.payload;
    },
    SET_ALARM_TONE_BY_INDEX: (state, action: PayloadAction<number>) => {
      if (action.payload < 0) {
        throw Error("Couldn't set alarm tone by index because given index is less than 0");
      }
      else if (action.payload > state.alarmTonesData.length - 1) {
        throw Error("Couldn't set alarm tone by index because index is out of bounds");
      }
      else {
        state.alarmTone = state.alarmTonesData[action.payload];
      }
    },
    SET_ALARM_TONE_BY_ID: (state, action: PayloadAction<string>) => {      
      let audioClip: AudioClipInfo | undefined = getAudioClipById(state.alarmTonesData, action.payload);
      if (audioClip === undefined) {
        throw Error("Couldn't set alarm tone, invalid id given");
      }
      state.alarmTone = audioClip;
    },
    
    SET_ACTIVE: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    SET_LOOP: (state, action: PayloadAction<boolean>) => {
      state.loop = action.payload;
    },
    SET_PLAY_ALARM_TONE: (state, action: PayloadAction<boolean>) => {
      state.playAlarmTone = action.payload;
    }
  }
});

// Export actions to be used in components
export const { SET_AUDIO_CLIP, SET_AUDIO_CLIP_BY_INDEX, SET_AUDIO_CLIP_BY_ID, SET_ACTIVE, SET_LOOP, 
  SET_ALARM_TONE, SET_ALARM_TONE_BY_INDEX, SET_ALARM_TONE_BY_ID, SET_PLAY_ALARM_TONE } = audioPlayerSlice.actions;

// Export the reducer to be used in the store
export default audioPlayerSlice.reducer;