import { useEffect, useRef } from 'react';
import { AudioPlayerState, SET_ALARM_TONE_BY_ID, SET_AUDIO_CLIP_BY_INDEX} from '../features/audioPlayerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { AudioClipInfo } from '../models/AudioClipInfo';
import { CountDownTimerState } from '../features/countdownTimerSlice';

export default function AudioPlayer() {
  /** Alarm tone length in milliseconds */
  const ALARM_TONE_LENGTH = 10 * 1000;
  /** If true alarm tones will only play for the length set in ALARM_TONE_LENGTH */
  const FIX_ALARM_TONE_LENGTH = true;

  const audioRef = useRef<HTMLAudioElement>(null);    
  
  const audioPlayerState = useSelector((state: RootState) => state.audioPlayer);
  const countdownTimerState: CountDownTimerState = useSelector((state: RootState) => state.countdownTimer);

  const dispatcher = useDispatch();

  function playAlarmTone() {
    if (audioPlayerState.alarmTone === null) {
      throw Error("Couldn't play alarm tone, alarm tone hasn't been set");
    }
    if (audioRef.current) {      
      audioRef.current.src = audioPlayerState.alarmTone.url;
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      if (FIX_ALARM_TONE_LENGTH) {
        setTimeout(() => {
          resetState();
        }, ALARM_TONE_LENGTH);
      }
    }
  }

  function resetState() {
    if (audioPlayerState.backgroundMusic === null) {
      return;
    } 

    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.src = audioPlayerState.backgroundMusic ? audioPlayerState.backgroundMusic.url : "";
    }
  }

  useEffect(() => {
    // dispatcher(SET_AUDIO_CLIP_BY_INDEX(0));
    dispatcher(SET_ALARM_TONE_BY_ID("birds_chirping"));
  }, []);

  useEffect(() => {
    if (countdownTimerState.reachedZero && audioPlayerState.playAlarmTone) {
      playAlarmTone();
    }
    else {
      resetState();
    }    
  }, [countdownTimerState.reachedZero, countdownTimerState.active]);

  return (
    <audio ref={audioRef} src={audioPlayerState.backgroundMusic?.url} controls hidden />    
  );
};

