import { useEffect, useRef, useState } from 'react'
import '../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { PeskelsApi } from '../PeskelsApi';
import { Photo } from 'pexels';
import { SET_CURRENT_PHOTO } from '../features/appSlice';

export default function BackgroundImage() {  
  const SLIDESHOW_INTERVAL = 15 * 1000;
   
  const [loading, setLoading] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);  

  const photos = useRef<Photo[]>([]);

  const slideshowImages = useSelector((state: RootState) => state.app.slideshowImages);
  const imageQuery = useSelector((state: RootState) => state.app.backgroundImageQuery);
  const dispatch = useDispatch();


  async function fetchImage() {
    const NUM_OF_IMAGES = 10;
    setLoading(true);
    let randPage = Math.floor(Math.random() * 10);
    let resultPhotos = await PeskelsApi.fetchImages(imageQuery, "landscape", NUM_OF_IMAGES, randPage);    
    if (resultPhotos !== undefined && photos.current) {
      photos.current = resultPhotos;
      setIndexToRandomNumber();
    }
    setLoading(false);
  }

  const hasMounted = useRef<boolean>();

  function setIndexToRandomNumber() {    
    if (photos) {
      let randIndex = Math.floor(Math.random() * photos.current.length);
      setIndex(randIndex);
      dispatch(SET_CURRENT_PHOTO(photos.current[randIndex]));
    }
  }

  useEffect(() => {
    const fetchData = async() => {
      await fetchImage();
    }    
    fetchData();
  }, [imageQuery]);

  useEffect(() => {
    const fetchData = async() => {
      await fetchImage();
    }
    if (!hasMounted.current) {
      fetchData();
      hasMounted.current = true;
    }

    // start slideshow timer
    const interval = setInterval(() => {      
      if (slideshowImages) {
        setIndexToRandomNumber();      
      }
    }, SLIDESHOW_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fullscreen-component" style={{padding: 0, margin: 0}}>
      {
        photos.current.length > 0 && !loading && (
          <img src={photos.current[index].src.original} alt="Background Image" className="fullscreen-image" onError={() => {alert("Errored");}}/>      
        )
      }
    </div>
  )
}
