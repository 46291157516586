import { Modal, Box, Typography, Checkbox} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { HIDE_SETTINGS_MODAL, SET_BACKGROUND_IMAGE_QUERY, SET_SITE_COLOUR, SET_SLIDESHOW_IMAGES} from "../features/appSlice";
import '../styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSquare } from "@fortawesome/free-solid-svg-icons";
import { SET_ALARM_TONE_BY_ID, SET_PLAY_ALARM_TONE } from "../features/audioPlayerSlice";
import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "../components/CustomSelect";

export default function SettingsModal() {
  const siteColour = useSelector((state: RootState) => state.app.siteColour);
  const disabledSiteColour = useSelector((state: RootState) => state.app.disabledSiteColour);
  const open = useSelector((state: RootState) => state.app.showSettingsModal);
  const audioPlayerState = useSelector((state: RootState) => state.audioPlayer);    

  const menuSubsection: React.CSSProperties = {
    margin: "0.33em 1em"
  };
  const menuSubsectionContent: React.CSSProperties = {
    display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", padding: "0em", marginTop: "0.2em"
  };
  const sectionHeader: React.CSSProperties = {
    color: siteColour
  };


  const dispatcher = useDispatch();  

  function handleClose() {
    dispatcher(HIDE_SETTINGS_MODAL());    
  }

  function SiteColourMenu() {

    let header = {...sectionHeader};
    header.alignItems = "center";

    let content = {...menuSubsectionContent};
    content.width = "100%";
    content.flexDirection = "column";

    const colours = [
      "#244855",
      "#003135",
      "#0FA4AF",
      "#3D52A0",
      "#6A58A2",
      "#865D36",
      "#E7717D",
      "#00859c",

    ];
    const [selectedColour, setSelectedColour] = useState<string>(siteColour);

    useEffect(() => {
      setSelectedColour(siteColour);
    }, []);

    function ColourOption(props: {colour: string, selected: boolean, onClicked: () => void}) {
      return (
        <div style={{padding: "0em 0.2em 0em 0.2em", borderRadius: "5px", backgroundColor: props.selected ? "white" : ""}}
          onClick={props.onClicked}
        >
          <FontAwesomeIcon icon={faSquare} style={{color: props.colour, fontSize: "30px"}}/>
        </div>
      );
    }

    function updateSiteColour() {
      if (selectedColour !== "" && selectedColour !== siteColour) {
        dispatcher(SET_SITE_COLOUR(selectedColour));
      }
    }

    return (
      <div style={menuSubsection}>
        <div style={{alignItems: "center"}}>
          <h2 style={header}>Site Colour</h2>        
        </div>
        <div style={content}>
          <div style={{display: "flex", flexDirection: "row", padding: "0em 0em 0.5em 0em"}}>
            {
              colours.map((colour, index) => (
                <ColourOption key={index} 
                  colour={colour} 
                  selected={colour === selectedColour}
                  onClicked={() => {setSelectedColour(colour);}}
                />
              ))
            }
          </div>  
          <div style={{display: "flex", flexDirection: "row", padding: "0.3em 0em 0.3em 0em"}}>
            <span style={{color: siteColour}}>Selected Colour:</span>
            <FontAwesomeIcon icon={faSquare} style={{color: selectedColour, marginLeft: "0.33em", fontSize: "25px"}}/>
          </div>
          <button className="menu-button" 
            style={{backgroundColor: siteColour}}
            onClick={updateSiteColour}
          >
            Update colour
          </button>        
        </div>
      </div>
    )
  }

  function AlarmToneSelectMenu() {
    const audioRef = useRef<HTMLAudioElement>(null); 
    const playToneCheckbox = useRef<HTMLInputElement>(null);
    const [playingAlarmTone, setPlayingAlarmTone] = useState<boolean>(false);

    function handleSelectChanged(args: any) {
      const alarmToneId : string | undefined = args.target.value;
      if (alarmToneId === undefined) {
        throw Error(`Couldn't handle select change - alarm tone id is invalid (${alarmToneId})`);
      }
      dispatcher(SET_ALARM_TONE_BY_ID(alarmToneId));
      setPlayingAlarmTone(false);
    }

    function playSelectedAlarmTone() {
      if (audioRef.current) {
        // reset clip to play from beginning
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        setPlayingAlarmTone(true);
      }
    }

    function stopPlayingSelectedAlarmTone() {
      if (audioRef.current) {
        audioRef.current.pause();
        setPlayingAlarmTone(false);
      }
    }

    function togglePlayAlarmTone() {
      dispatcher(SET_PLAY_ALARM_TONE(!audioPlayerState.playAlarmTone));
    }

    return (
      <div style={menuSubsection}>
        <h2 style={sectionHeader}>Alarm Tone</h2>
        <div>
          <input ref={playToneCheckbox} type="checkbox" id="play-alarm-tone-checkbox" name="play arlam tone checkbox" 
            checked={audioPlayerState.playAlarmTone} onChange={togglePlayAlarmTone}/>
          <span>Play alarm tone</span>
        </div>
        <div style={menuSubsectionContent}>
          <CustomSelect style={{flex: "8"}} onChange={handleSelectChanged} value={audioPlayerState.alarmTone?.id}>
            <option value='birds_chirping'>Birds</option>
            <option value='digital_clock'>Digital Clock</option>
            <option value='tranquil'>Tranquil</option>
          </CustomSelect>          
          <button style={{flex: "4", marginLeft: "1em", backgroundColor: siteColour}} className="menu-button" onClick={playingAlarmTone ? stopPlayingSelectedAlarmTone : playSelectedAlarmTone}>{playingAlarmTone ? "Stop Tone" : "Play Tone"}</button>
        </div>

        <audio src={audioPlayerState.alarmTone?.url} ref={audioRef} controls hidden/>
      </div>
    )
  }

  function BackgroundMusicMenu() {    
    return (
      <div style={menuSubsection}>
        <h2 style={sectionHeader}>Background Music</h2>        
        <div style={menuSubsectionContent}>
          <CustomSelect onChange={(event: any) => {}} value={"nature_sounds"} style={{flex: "1"}}>
            <option value='nature_sounds'>Nature Sounds</option>
            <option value='ambient_music' disabled={true}>Ambient Music</option>
            <option value='guided_meditation' disabled={true}>Guided Meditation</option>
            <option value='world_music' disabled={true}>World Music</option>
            <option value='space_music'>Space Music</option>
          </CustomSelect>
        </div>
      </div>
    )
  }

  function BackgroundImagesMenu() {
    const slideshowCheckbox = useRef<HTMLInputElement>(null);  
    const [selectedOption, setSelectedOption] = useState<string>('');    

    const slideshowImages = useSelector((state: RootState) => state.app.slideshowImages);

    function toggleEnableSlideshow() {
      if (slideshowCheckbox.current) {
        dispatcher(SET_SLIDESHOW_IMAGES(slideshowCheckbox.current.checked));
        
      }
    }

    function handleSelection(event: any) {            
      let query = idToQuery(event.target.value);
      setSelectedOption(event.target.value);
      localStorage.setItem('selectedQueryValue', event.target.value); 
      if (query) {
        dispatcher(SET_BACKGROUND_IMAGE_QUERY(query));
      }      
    }

    function idToQuery(id: string) {
      switch(id) {
        case "landscapes":
          return "landscapes mountain forests land fields";        
        case "zen":
          return "zen peaceful comfortable cosy happy spiritual";
        case "space":
          return "space cosmic galaxy";
        case "water":
          return "lake water river ocean";  
        case "sunrises_and_sunsets":
          return "sunsets sunrises sun"        
        case "default":
          return "landscapes mountains lakes forests"
      }
    }

    useEffect(() => {
      // load selected value 
      const storedValue = localStorage.getItem('selectedQueryValue');
      if (storedValue) {
        setSelectedOption(storedValue);
      }
    }, []);

    return (
      <div style={menuSubsection}>
        <h2 style={sectionHeader}>Background Images</h2>
        <div>
          <input ref={slideshowCheckbox} type="checkbox" id="slideshow-checkbox" name="slideshow checkbox" 
             onChange={toggleEnableSlideshow} checked={slideshowImages}/>
          <span>Slideshow Images</span>
        </div>
        <div style={menuSubsectionContent}>
          <CustomSelect onChange={handleSelection} value={selectedOption} style={{flex: "1"}}>
            <option value='landscapes'>Landscapes</option>
            <option value='space'>Cosmics & Space</option>
            <option value='water'>Water Scenes</option>
            <option value='sunrises_and_sunsets'>Sunrises & Sunsets</option>
            <option value='zen'>Zen</option>

          </CustomSelect>             
        </div>        
      </div>
    )
  }

  function DonateMenu() {
    const contentStyling = {...menuSubsectionContent};
    contentStyling.textAlign = "center";
    contentStyling.width = "100%";

    return (
      <div style={menuSubsection}>
        <h2 style={sectionHeader}>Donate 😊</h2>       
        <span>Find this app useful? </span> 
        <div style={contentStyling}>
        <a href="https://www.buymeacoffee.com/matthancocx">
          <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a pizza&emoji=🍕&slug=matthancocx&button_colour=BD5FFF&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00" />
        </a>
        </div>
      </div>
    )
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      
    >      
      <div className="modal-content">
        <button className="close-modal-button" onClick={handleClose} style={{backgroundColor: siteColour}}><FontAwesomeIcon icon={faClose}/></button>
        <h1 style={{color: siteColour, textAlign: "center", lineHeight: "40%"}}>Settings Modal</h1>
        <SiteColourMenu/>
        <AlarmToneSelectMenu/> 
        <BackgroundMusicMenu/>       
        <BackgroundImagesMenu/>
        <DonateMenu/>
        
      </div>
    </Modal>
  );
}