import React, { useEffect, useRef, useState } from 'react'

export default function CustomSelect(props: {children: React.ReactNode, onChange: (event: any) => void, value: string | number | readonly string[] | undefined, style?: React.CSSProperties}) {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (event: any) => {
    // Delay to ensure the dropdown closing has been processed
    setTimeout(() => {      
      if (!selectRef.current?.contains(document.activeElement)) {
        setIsFocused(false);
      }
    }, 0);
  };

  return (
    <select ref={selectRef} style={props.style} onFocus={handleFocus} onBlur={handleBlur} onSelect={() => {setIsFocused(false);}} onClick={() => {if (isFocused) {setIsFocused(false);}}}
      className={`menu-select-element ${isFocused ? "selected" : ""}`} onChange={props.onChange} value={props.value}>
      {
        props.children
      }
    </select>
  )
}
