import '../styles.css';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_SETTINGS_MODAL } from '../features/appSlice';
import RoundIconButton from './generic-components/RoundButton';
import { RootState } from '../store';

export default function SettingsButton() {

  const dispatcher = useDispatch();

  const siteColour = useSelector((state: RootState) => state.app.siteColour);
  const disabledColour = useSelector((state: RootState) => state.app.disabledSiteColour);

  return (
    <div style={{position: "absolute", top: "1em", right: "1em", zIndex: "999"}}>
      <RoundIconButton size={45} icon={faCog} type={'filled'} colour={siteColour} disabledColour={disabledColour} onClick={() => {dispatcher(SHOW_SETTINGS_MODAL());}}/>      
    </div>
  )
}
