// Import createSlice from Redux Toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**Default start time in seconds */
const DEFAULT_START_TIME = 60;

// Define a type for the slice state
export interface CountDownTimerState {
  reachedZero: boolean;
  active: boolean;
  startTime: number;
  secondsLeft: number;
}

// Define the initial state using that type
const initialState: CountDownTimerState = {
  reachedZero: false,
  active: false,
  startTime: getTimeFromSearchParams(),
  secondsLeft: 0
};

export function getTimeFromSearchParams(): number {       
  const queryParams = new URLSearchParams(window.location.search);
  const timerValue = queryParams.get('timer');
  let timerInt = Math.floor(DEFAULT_START_TIME / 60);
  if (timerValue) {
    timerInt = parseInt(timerValue, 10);
  }

  if (!isNaN(timerInt)) {
    const timeInSeconds = timerInt * 60
    return timeInSeconds;
  } else {
    return DEFAULT_START_TIME;
  }
}

export const countdownTimerSlice = createSlice({
  name: 'countdownTimer',
  initialState,
  reducers: {
    SET_ACTIVE: (state, action: PayloadAction<boolean>) => {      
      state.active = action.payload;
    },
    TOGGLE_ACTIVE: state => {      
      state.active = !state.active;
    },
    SET_REACHED_ZERO: (state, action: PayloadAction<boolean>) => {      
      state.reachedZero = action.payload;
    },
    SET_START_TIME: (state, action: PayloadAction<number>) => {      
      state.startTime = action.payload;
      state.secondsLeft = action.payload;
    },
    SET_SECONDS_LEFT: (state, action: PayloadAction<number>) => {      
      state.secondsLeft = action.payload;
    }
  }
});

// Export actions to be used in components
export const { SET_ACTIVE, TOGGLE_ACTIVE, SET_REACHED_ZERO, SET_START_TIME, SET_SECONDS_LEFT } = countdownTimerSlice.actions;

// Export the reducer to be used in the store
export default countdownTimerSlice.reducer;
